import SEO from '../components/core/meta/SEO';
import React from 'react';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import { graphql, useStaticQuery } from 'gatsby';
import SmallPartnerList from '../components/partials/PartnersList/SmallPartnerList';
import TwoPartLayout from '../components/partials/TwoPartLayout/TwoPartLayout';
import { AziendeFarmaceutichePageQuery } from '../../generated/graphql-types';
import BackgroundImage from 'gatsby-background-image';
import BrochureBanner from '../components/partials/BrochureBanner/BrochureBanner';
import ContactFormButton from '../components/partials/ContactFormButton/ContactFormButton';
import tw, { css } from 'twin.macro';

const patientSupportList = [
  {
    title:
      'Grazie al supporto non condizionante di GSK abbiamo offerto il nostro supporto alle pazienti affette da tumore ovarico.',
    list: [
      '50 pazienti supportate nel loro percorso di cura',
      '+1.000 servizi di accompagnamento erogati in 18 mesi',
      '7 centri ospedalieri coinvolti, in 4 città',
      'Accompagnamenti per terapie, visite di controllo, dimissioni ospedaliere, esami diagnostici, commissioni, accompagnamenti a eventi dedicati al patient engagement',
      '5/5 livello di soddisfazione di pazienti e clinici',
    ],
  },
  {
    title:
      'Supporto al paziente affetto da patologie della retina con Novartis',
    list: [
      '85 pazienti supportati nel loro percorso di cura',
      '1.400 servizi di accompagnamento e supporto erogati',
      '6 centri ospedalieri attivati in 4 città',
      '5/5 livello di soddisfazione di pazienti e clinici',
    ],
  },
  {
    title: 'Supporto al paziente onco-ematologico con Leukemia',
    list: [
      '20 pazienti supportati nel loro percorso di cura',
      '1.500 ore di affiancamento totali erogate',
      '50.000 chilometri percorsi per accedere alle cure',
      '5/5 livello di soddisfazione di pazienti e clinici',
    ],
  },
];

const mainContent = [
  'Accompagnamento e affiancamento durante visite e terapie: UGO permette al paziente di raggiungere il luogo della terapia e sta con lui all’interno, aiutandolo nella movimentazione, nell’orientamento, nella burocrazia. E’ un supporto logistico e psicologico che favorisce aderenza e engagement.',
  'Consegna di farmaci, referti, spesa a domicilio: UGO svolge per conto del paziente il ritiro di farmaci e referti da ambulatori, ospedale e farmacia. Se ha bisogno di altre commissioni e spesa, UGO c’è.',
  'Supporto per la telemedicina: in occasione di visite on-line o di rilevamento dati del pazienti, UGO aiuta il paziente ad accedere ai sistemi informatici e lo supporta nelle attività che possono risultare ostiche. Può farlo sempre o solo all’inizio, come abilitatore.',
  'Dal 2019 abbiamo supportato più di 15.000 pazienti in diverse aree terapeutiche. La valutazione media del servizio è di 4.9/5. Il supporto è disponibile in più di 10 città italiane grazie a una rete di oltre 500 caregiver che siamo noi a selezionare e formare grazie alla UGO Academy.',
  'Collaboriamo con le aziende farmaceutiche in diverse modalità: sponsorizzazioni per attività di posizionamento e branding; attività di formazione e sensibilizzazione di comunità di pazienti o all’interno dell’azienda; servizi di welfare; Patient Support Programs.',
];

const patientContent = [
  'Convivere con una patologia cronica è un percorso impegnativo di gestione della vita quotidiana e della cura. Richiede energia e tempo, al malato e alla famiglia. Abbiamo messo a disposizione dei pazienti fragili un caregiver professionale: UGO. UGO assiste il paziente nel ciclo terapico, in tutte le necessità: lo supporta nel raggiungere l’ospedale e nelle attività all’interno; lo stimola a rispettare il calendario; agevola la comunicazione medico-paziente-famiglia; intercetta gli Eventi Avversi.\n',
  "E' un supporto organizzativo, fisico e psicologico che favorisce l’aderenza alla terapia, la motivazione personale, il patient engagement e migliora il life balance. L'autonomia e la fiducia nella cura aumentano l'aderenza e l'efficacia del percorso. UGO è un'azione concreta di cura della persona prima ancora del paziente.",
];

const patientList = [
  {
    boldText:
      'Accompagnamento e affiancamento durante visite e terapie ospedaliere:',
    text:
      'UGO permette al paziente di raggiungere il luogo della terapia e sta con lui all’interno, aiutandolo nella movimentazione, nell’orientamento, nella burocrazia e tenendogli compagnia',
  },
  {
    boldText: 'Consegna di farmaci, referti, spesa a domicilio:',
    text:
      'UGO svolge per conto del paziente il ritiro di farmaci e referti da ambulatori, ospedale e farmacia. Se ha bisogno di altre commissioni e spesa, UGO c’è.',
  },
  {
    boldText: 'Supporto per la telemedicina:',
    text:
      'in occasione di visite on-line o di rilevamento dati del pazienti, UGO aiuta il paziente ad accedere ai sistemi informatici e lo supporta nelle attività che possono risultare ostiche.',
  },
];

const serviceContent = [
  'Partiamo dal bisogno per definire il tipo di progetto più adatto ad ogni azienda. Partiamo dall’obiettivo.',
  'Nella nostra esperienza passata abbiamo avviato diverse sponsorship per supportare pazienti, cittadini, clinici e personale ospedaliero e per lanciare messaggi importanti.',
  'Accanto al servizio di accompagnamento e supporto ci sono attività di comunicazione, sensibilizzazione e formazione che hanno un grande valore.',
];

export const listItemStyle = css`
  &::marker {
    color: #9d4de1;
  }
`;

const AziendeFarmaceutichePage = () => {
  const aziendeFarmaceuticheData = useStaticQuery<
    AziendeFarmaceutichePageQuery
  >(graphql`
    query AziendeFarmaceutichePage {
      headerBackground: file(relativePath: { eq: "b2b/headers/person.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      patientImage: file(relativePath: { eq: "b2b/patient.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      serviceImage: file(relativePath: { eq: "b2b/service.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO url={'/aziende-farmaceutiche'} title={'Pharma'} />
      <BackgroundImage
        fluid={aziendeFarmaceuticheData.headerBackground.childImageSharp.fluid}
      >
        <div
          tw="bg-black bg-opacity-60 py-16 flex"
          style={{ minHeight: '460px' }}
        >
          <Container tw="flex flex-col justify-center">
            <Heading variant="h2" tw="text-center text-white">
              {'Curare la persona, per guarire il paziente.'}
            </Heading>
            <div tw="flex justify-center">
              <ContactFormButton />
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <div tw="pt-16">
        <Container tw="mb-16">
          <div tw="mb-16">
            <h3 tw="text-h3-mobile md:text-h3 tracking-h3 font-bold mb-8">
              {
                'Aiutiamo il sistema sanitario e il paziente a gestire il percorso di cura per migliorare la qualità della sua vita e della sua famiglia.'
              }
            </h3>
            {mainContent.map((m, idx) => (
              <p
                key={idx}
                style={{
                  fontSize: '17px',
                  lineHeight: '32px',
                  marginBottom: '1rem',
                }}
              >
                {m}
              </p>
            ))}
          </div>
          <div tw="flex justify-center">
            <ContactFormButton />
          </div>
        </Container>
        <Container tw="mb-16">
          <SmallPartnerList
            partnerList={[
              'partner1',
              'partner51',
              'partner16',
              'partner37',
              'partner5',
            ]}
          />
        </Container>
        <TwoPartLayout
          imageFluid={
            aziendeFarmaceuticheData.patientImage.childImageSharp.fluid
          }
          downloadPharmaPdfButton={true}
        >
          <h4 tw="text-h4-mobile md:text-h4 tracking-h4 font-bold mb-8">
            {'Patient Support Program Experience'}
          </h4>
          {patientSupportList.map(p => (
            <>
              <p
                style={{
                  fontSize: '17px',
                  lineHeight: '32px',
                  marginBottom: '1rem',
                }}
              >
                <div tw="mb-4">
                  <b>{p.title}</b>
                </div>
                <ol tw="list-disc" style={{ listStylePosition: 'inside' }}>
                  {p.list.map((l, index) => (
                    <li css={listItemStyle} key={index}>
                      {l}
                    </li>
                  ))}
                </ol>
              </p>
            </>
          ))}
        </TwoPartLayout>
        <Container tw="mb-16">
          <div tw="mb-8">
            <h3 tw="text-h3-mobile md:text-h3 tracking-h3 font-bold mb-8">
              {'UGO accanto al paziente'}
            </h3>
            {patientContent.map((m, idx) => (
              <p
                key={idx}
                style={{
                  fontSize: '17px',
                  lineHeight: '32px',
                  marginBottom: '1rem',
                }}
              >
                {m}
              </p>
            ))}
          </div>
          <div>
            <h5 tw="text-h5-mobile md:text-h5 tracking-h5 font-bold mb-8">
              {'UGO supporta il paziente e i suoi cari in ogni momento.'}
            </h5>
            <p
              style={{
                fontSize: '17px',
                lineHeight: '32px',
                marginBottom: '1rem',
              }}
            >
              <ol tw="list-disc" style={{ listStylePosition: 'inside' }}>
                {patientList.map((p, index) => (
                  <li key={index}>
                    <b>{p.boldText}</b>
                    {' ' + p.text}
                  </li>
                ))}
              </ol>
            </p>
          </div>
        </Container>
        <TwoPartLayout
          imageFluid={
            aziendeFarmaceuticheData.serviceImage.childImageSharp.fluid
          }
          halfLayout={true}
          removeBottomMargin={true}
        >
          <h4 tw="text-h4-mobile md:text-h4 tracking-h4 font-bold mb-8">
            {'Non solo PSP.'}
          </h4>
          {serviceContent.map((t, idx) => (
            <p
              key={idx}
              style={{
                fontSize: '17px',
                lineHeight: '32px',
                marginBottom: '1rem',
              }}
            >
              {t}
            </p>
          ))}
        </TwoPartLayout>
        <BrochureBanner />
      </div>
    </>
  );
};

export default AziendeFarmaceutichePage;
